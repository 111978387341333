import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { createSlug } from '../../util/urlHelpers';
import { ExternalLink } from '../../components';

import css from './SectionRelatedBlogs.module.css';

import ecommercePostImage1 from './images/ecommerce_1.jpg';
import ecommercePostImage2 from './images/ecommerce_2.jpg';
import ecommercePostImage3 from './images/ecommerce_3.jpg';
import ecommercePostImage4 from './images/ecommerce_4.jpg';

import tradingPostImage1 from './images/trading_1.jpg';
import tradingPostImage2 from './images/trading_2.jpg';
import tradingPostImage3 from './images/trading_3.jpg';
import tradingPostImage4 from './images/trading_4.jpg';

import propertyPostImage1 from './images/property_1.jpg';
import propertyPostImage2 from './images/property_2.jpg';
import propertyPostImage3 from './images/property_3.jpg';
import propertyPostImage4 from './images/property_4.jpg';

import businessCoachingPostImage1 from './images/businessCoaching_1.jpg';
import businessCoachingPostImage2 from './images/businessCoaching_2.jpg';
import businessCoachingPostImage3 from './images/businessCoaching_3.jpg';
import businessCoachingPostImage4 from './images/businessCoaching_4.jpg';

const ecommercePosts = [
  {
    title: 'You Won’t Believe How Easy It Is to Start Making Money from Home in 2024 with E-Commerce!',
    link: 'https://mentorme.io/blog/2024-ecommerce-business-launch-earn-from-home/',
    image: ecommercePostImage1,
  },
  {
    title: 'Is E-commerce Legit?',
    link: 'https://mentorme.io/blog/is-e-commerce-legit/',
    image: ecommercePostImage2,
  },
  {
    title: '2024 Dropshipping Guide: Is It Still Profitable',
    link: 'https://mentorme.io/blog/dropshipping-2024-profitability-trends-2/',
    image: ecommercePostImage3,
  },
  {
    title: 'How to Start an Amazon FBA Business with Wholesale',
    link: 'https://mentorme.io/blog/how-to-start-amazon-fba/',
    image: ecommercePostImage4,
  },
];

const tradingPosts = [
  {
    title: 'The 3 Most Important Factors to Consider When Valuing Stocks',
    link: 'https://mentorme.io/blog/the-3-most-important-factors-to-consider-when-valuing-stocks/',
    image: tradingPostImage1,
  },
  {
    title: 'From Interest Rates to Inflation: How to Interpret and Use Economic Indicators in Your Trades',
    link: 'https://mentorme.io/blog/interest-rates-to-inflation-interpret-and-use-economic-indicators/',
    image: tradingPostImage2,
  },
  {
    title: 'The Ultimate Guide to Financial Instruments for Beginners',
    link: 'https://mentorme.io/blog/the-ultimateguide-to-financial-instruments-for-beginners-in-2023/',
    image: tradingPostImage3,
  },
  {
    title: 'Starting a Trading Career',
    link: 'https://mentorme.io/blog/starting-a-trading-career/',
    image: tradingPostImage4,
  },
];

const propertyPosts = [
  {
    title: 'Are HMOs a Good Investment for First-Time Property Investors in 2023?',
    link: 'https://mentorme.io/blog/are-hmos-a-good-investment-for-first-time-property-investors/',
    image: propertyPostImage1,
  },
  {
    title: 'Rent to Rent (R2R) vs. Buy to Let (BTL) Properties: What’s the Difference?',
    link: 'https://mentorme.io/blog/rent-to-rent-vs-buy-to-let-properties-whats-the-difference/',
    image: propertyPostImage2,
  },
  {
    title: 'What is Deal Sourcing?',
    link: 'https://mentorme.io/blog/how-to-start-a-property-empire-with-no-capital-in-2023/',
    image: propertyPostImage3,
  },
  {
    title: 'Property Buying & Sales',
    link: 'https://mentorme.io/blog/property-buying-and-sales/',
    image: propertyPostImage4,
  },
];

const businessCoachingPosts = [
  {
    title: '5 Best Side Hustles',
    link: 'https://mentorme.io/blog/side-hustle-ideas/',
    image: businessCoachingPostImage1,
  },
  {
    title: 'Choosing Your Path: How to Start a Side Hustle That Fits You Best',
    link: 'https://mentorme.io/blog/choosing-your-path-how-to-start-a-side-hustle-that-fits-you-best/',
    image: businessCoachingPostImage2,
  },
  {
    title: 'How to find a mentor for your career',
    link: 'https://mentorme.io/blog/how-to-find-a-mentor/',
    image: businessCoachingPostImage3,
  },
  {
    title: 'How to Get Unstuck and Start Living the Life You Want',
    link: 'https://mentorme.io/blog/how-to-get-unstuck-and-start-living-the-life-you-want/',
    image: businessCoachingPostImage4,
  },
];

class BlogPostImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(BlogPostImage);

const locationLink = (title, link, image) => {
  const titleText = <span className={css.blogPostTitle}>{title}</span>;

  return (
    <ExternalLink key={createSlug(title)} href={link} className={css.blogPost}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={title} className={css.blogPostImage} />
        </div>
      </div>
      <div className={css.linkText}>{titleText}</div>
    </ExternalLink>
  );
};

const SectionRelatedBlogs = props => {
  const { rootClassName, className, intl, category } = props;
  const classes = classNames(rootClassName || css.root, className);

  if(!category) return null

  const postsData = {
    e_commerce: ecommercePosts,
    trading: tradingPosts,
    property: propertyPosts,
    business_coaching: businessCoachingPosts,
  }

  const titleData = {
    e_commerce: "Blogs related to E-Commerce",
    trading: "Blogs related to Trading & Investing",
    property: "Blogs related to Property",
    business_coaching: "Blogs related to Entrepreneurship",
  }

  const descriptionData = {
    e_commerce: "Dive into the world of e-commerce with our expert insights. From mastering Amazon FBA to scaling your dropshipping business, we provide actionable tips and strategies to build profitable online stores. Discover how to dominate the digital marketplace and achieve financial freedom.",
    trading: "Unlock your potential in trading with our in-depth guides. Learn everything from stock market fundamentals to crypto trading strategies. Stay ahead of the trends with expert advice designed to help you grow your portfolio and take control of your financial future.",
    property: "Start building your property empire with zero to minimal capital. Explore tips on buy-to-let investments, flipping houses, and property sourcing strategies. Whether you're a beginner or a seasoned investor, our property blogs help you unlock lucrative opportunities in real estate.",
    business_coaching: "Take your entrepreneurial journey to the next level. Discover business ideas, productivity hacks, and growth strategies tailored for side hustlers and full-time entrepreneurs. Learn how to scale your startup and turn your passion into a thriving business.",
  }


  return (
    <div className={classes}>
      <div className={css.titleContainer}>
        <div className={css.title}>
          {titleData?.[category]}
        </div>
        <div className={css.subtitle}>
          {descriptionData?.[category]}
        </div>
      </div>

      <div className={css.recomTop}>
        <div className={css.recomTitle}>
          <FormattedMessage id="SectionExplore.recommendationsTitle" />
        </div>
        <ExternalLink key="blogPosts" href="https://mentorme.io/blog/" className={css.seeAll}>
          <FormattedMessage id="SectionExplore.actionButton" />
        </ExternalLink>
      </div>

      <div className={css.blogPosts}>
        {postsData?.[category]?.map(p => locationLink(p.title, p.link, p.image))}
      </div>
    </div>
  );
};

SectionRelatedBlogs.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionRelatedBlogs.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionRelatedBlogs;
