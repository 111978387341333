import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import {
  ListingCard,
  PaginationLinks,
  SectionCategories,
  SectionFaqContent,
  SectionSchedule,
} from '../../components';
import SectionCategoryOthers from './SectionCategoryOthers';
import css from './SearchResultsPanel.module.css';
import SectionRelatedBlogs from '../SectionRelatedBlogs/SectionRelatedBlogs';
import CoursePaper from '../CoursePaper/CoursePaper';
import { ensureListing, ensureUser } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import SectionNotSure from '../SectionNotSureWhichMentor/SectionNotSure';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    intl,
    category,
    categoryOptions,
    listings,
    pagination,
    search,
    setActiveListing,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const categoryOption = category ? categoryOptions.find(option => option.key === category) : 0;
  const categoryFooterBlock = category ? (
    <>
      <SectionFaqContent categoryOption={categoryOption} intl={intl} />
      <SectionRelatedBlogs intl={intl} category={category} />
      <h2 className={css.categoryDiscoverTitle}>
        <FormattedMessage id="SearchResultsPanel.pageSubheaderDiscoverMore" />
      </h2>
      <SectionCategoryOthers categoryOption={categoryOption} />
    </>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {listings.map(l => {
          const currentListing = ensureListing(l);
          const id = currentListing.id.uuid;
          const { title = '', price, publicData = {} } = currentListing.attributes;
          const { type: listingType, numberOfReviews = 0, averageRating = 0 } = publicData;
          const slug = createSlug(title);
          const author = ensureUser(l.author);
          const { formattedPrice, priceTitle } = priceData(price, intl);

          const unitTranslationKey =
            listingType === 'course' ? 'ListingCard.perDay' : 'ListingCard.perUnit';
          return (
            <CoursePaper
              key={id+"listing"}
              intl={intl}
              link={`/pl/${slug}/${id}`}
              price={formattedPrice}
              title={l.attributes.title}
              ranking={(averageRating/1000).toFixed(1)}
              rankingAmount={numberOfReviews}
              unit={unitTranslationKey}
              mentorName={author.attributes.profile.displayName}
              image={l.images[0]?.attributes.variants['landscape-crop'].url}
            />
          );
        })}
        {props.children}
      </div>
      {paginationLinks}
      {categoryFooterBlock}
      <SectionNotSure/>
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
