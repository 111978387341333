import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import letterIcon from './letter-icon.png';
import bellIcon from './bell-icon.png';

import css from './TopbarDesktop.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    searchHidden,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    heroImageIndex = '',
    isTransparentTopbar = false
  } = props;
  const [mounted, setMounted] = useState(false);
  const [isWhite, setIsWhite] = useState(!isTransparentTopbar);
  const history = useHistory();

  const onScroll = () => {
    setIsWhite(window.scrollY > 50);
  };

  useEffect(() => {
    if (isTransparentTopbar) {
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    } else {
      setIsWhite(true);
    }
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const isAdmin =
    authenticatedOnClientSide && currentUser && currentUser.attributes.profile.metadata.isAdmin;
  const isMainAdmin = isAdmin && currentUser?.id?.uuid === process.env.REACT_APP_MAIN_ADMIN_ID;
  const isMentor = currentUser?.attributes?.profile?.publicData?.role === 'mentor';

  const classes = classNames(rootClassName || (isWhite && mounted) ? css.rootWhite : css.root, className);

  const search = (
    <TopbarSearchForm
      className={isWhite ? css.whiteSearchLink : css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      searchHidden={searchHidden}
    />
  );

  const applyToBeAMentorLink = !currentUser ? (
    <NamedLink className={css.applyToBeAMentorLink} name="SignupPage">
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.applyToBeAMentor" />
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const adminManageUsersLink = isMainAdmin ? (
    <MenuItem key="AdminManageUsersPage">
      <NamedLink
        className={classNames(css.adminManageUsersLink, currentPageClass('AdminManageUsersPage'))}
        name="AdminManageUsersPage"
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.adminManageUsersLink" />
      </NamedLink>
    </MenuItem>
  ) : null;

  const adminManageReferralsLink = isMainAdmin ? (
    <MenuItem key="AdminManageReferralsPage">
      <NamedLink
        className={classNames(
          css.adminManageUsersLink,
          currentPageClass('AdminManageReferralsPage')
        )}
        name="AdminManageReferralsPage"
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.adminManageReferralsLink" />
      </NamedLink>
    </MenuItem>
  ) : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: 'sales' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.orders" />
      </span>
    </NamedLink>
  ) : null;

  const ordersLink = currentUserHasListings ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: 'sales' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.sales" />
      </span>
    </NamedLink>
  ) : null;

  

  const lettersLink = authenticatedOnClientSide ? (
    <NamedLink className={css.inboxLink} name="ConversationsPage">
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
      </span>
    </NamedLink>
  ) : null;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {isMentor ? (
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
        ) : null}
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        {adminManageUsersLink}
        {adminManageReferralsLink}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const e_commerceLink = (
    <NamedLink
      name="CategoryPage"
      params={{ category: 'e_commerce' }}
      className={css.resourcesLink}
    >
      E-Commerce
    </NamedLink>
  );

  const tradingLink = (
    <NamedLink name="CategoryPage" params={{ category: 'trading' }} className={css.resourcesLink}>
      Trading & Investing
    </NamedLink>
  );

  const propertyLink = (
    <NamedLink name="CategoryPage" params={{ category: 'property' }} className={css.resourcesLink}>
      Property
    </NamedLink>
  );

  const enterprenuershipLink = (
    <NamedLink
      name="CategoryPage"
      params={{ category: 'business_coaching' }}
      className={css.resourcesLink}
    >
      Entrepreneurship
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.topRow}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format={isWhite ? 'desktop' : 'desktopLight'}
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
        </NamedLink>
        {search}
        {!authenticatedOnClientSide ? (
          <span className={css.linksHolder}>
            {applyToBeAMentorLink}
            {loginLink}
            {signupLink}
          </span>
        ) : (
          <span className={css.linksHolder}>
            {lettersLink}
            {inboxLink}
            {ordersLink}
            {profileMenu}
          </span>
        )}
      </div>
      <div className={css.bottomRow}>
        {e_commerceLink}
        {tradingLink}
        {propertyLink}
        {enterprenuershipLink}
      </div>
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
